/**
 * Responsive Iframes
 *
 * Ported from legacy CMS: trunk/webpack/entries/gallery_landing/unpacked/nbctools.js
 *
 * Note: because of the code in crossstalk.js, requests from a domain that do not end
 * with a '.com' won't be handled properly.
 */
const { location: { host, protocol } } = window;
const iframeAllowList = [
  'https://data.nbcstations.com',
  'https://media.nbcnewyork.com',
  `${protocol}//${host}`,
];

window.addEventListener(
  'message',
  (e) => {
    if (! iframeAllowList.includes(e.origin)) {
      return;
    }

    let payload = {};

    try {
      payload = JSON.parse(e.data || '');
    } catch (err) {
      return;
    }

    if ('undefined' === typeof payload.cmd) {
      return;
    }

    if ('resize' === payload.cmd) {
      // Resize the iframe.
      const iframe = document.getElementById(payload.id);
      if (iframe) {
        iframe.style.height = `${payload.height}px`;
      }
    } else if ('pscroll' === payload.cmd) {
      // Scroll the parent window to a Y coordinate within the iframe.
      const refFrame = document.getElementById(payload.parentFrameId);
      const refFrameOffset = refFrame.offsetTop;

      window.scrollTo(0, refFrameOffset + payload.localPos);
    }
  },
  false
);
